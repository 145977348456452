







































































































import Vue from 'vue'
import hasPricespiderBtn from 'library/mixins/has-pricespider-btn'
import hasVideoModalBtn from 'library/mixins/has-video-modal-btn'
import inViewportDirective from 'vue-in-viewport-directive'
Vue.directive('in-viewport', inViewportDirective)

import ButtonList from 'library/components/global/button-list'

export default

	components: { ButtonList }

	mixins: [ hasPricespiderBtn, hasVideoModalBtn ]

	props: block: Object

	data: ->
		playing: true

	computed:

		# Shorthands
		logo: -> @block.fullMarqueeLogo || []
		eyebrow: -> @block.fullMarqueeEyebrow || []

		# Block level classes
		classes: -> [
			@block.layout
		]
		video: -> [
				desktop: @block.ambientVideo
				mobile: @block.mobileAmbientVideo
		]

		hasVideo: ->
			!!@video?[0]?.desktop?.length or !!@video?[0]?.mobile?.length

		marqueeLink: -> @block?.marqueeLink

		mobileContentClasses: -> return 'empty-content' if !@block.copy

		videoLabel: -> if @playing then "Pause Marquee Video" else "Play Marquee Video"

	methods:
		handleClick: (e) ->
			e?.preventDefault()
			if @playing then @pauseVideos() else @playVideos()

		pauseVideos: ->
			@$refs.desktopVisual.pause()
			@$refs.mobileVisual.pause()
			@playing=false

		playVideos: ->
			@$refs.desktopVisual.play()
			@$refs.mobileVisual.play()
			@playing=true
